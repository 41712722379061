import http from ".././http-common.js";

//to get weekly details on side navbar
export default {
  weeklySummary(date) {
    return http
      .get(`/timesheet/api/weeklysummary/?week_start_date=${date}`)
      .then(res => {
        return res;
      });
  },

  markAttendance(date, flag) {
    return http
      .get(`/timesheet/api/absent/?date=${date}&is_present=${flag}`)
      .then(res => {
        return res;
      });
  }
};
