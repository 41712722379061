<template>
  <el-tabs v-model="activeName" @tab-click="handleClick">
    <el-tab-pane label="My Task" name="first">
      <CreateTask />
      <TaskList />
    </el-tab-pane>
    <el-tab-pane label="Team Task" name="second">
      <TeamTask v-if="activeName == 'second'" />
    </el-tab-pane>
  </el-tabs>
</template>

<script>
  import CreateTask from "@/components/home/createTask.vue";
  import TaskList from "@/components/home/taskList.vue";
  import TeamTask from "@/components/home/manager/TeamTask-list.vue";
  export default {
    components: {
      CreateTask,
      TeamTask,
      TaskList
    },
    data() {
      return {
        activeName: "first"
      };
    },
    methods: {
      handleClick() {
        //console.log("tab", this.activeName);
      }
    }
  };
</script>

<style lang="scss" scoped></style>
