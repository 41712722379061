<template>
  <transition
    appear
    appear-class="custom-appear-class"
    appear-to-class="custom-appear-to-class"
    appear-active-class="custom-appear-active-class"
  >
    <section>
      <!-- <TaskForm /> -->
      <transition name="component-fade" mode="out-in">
        <TaskTab />
      </transition>
    </section>
  </transition>
</template>

<script>
  // import TaskForm from "@/components/home/createTask.vue";
  import TaskTab from "@/components/home/manager/tasktab.vue";

  export default {
    name: "Task",
    components: {
      //     TaskForm,
      TaskTab
    }
  };
</script>

<style lang="scss" scoped></style>
