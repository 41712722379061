<template>
	<section class="side-nav" :class="{ hidenav: navFlag == true }">
		<div class="day-nav place-center">
			<div class="block1" @click="prevWeek">
				<img src="./../assets/images/arrow_back.svg" />
			</div>

			<div class="block2">
				<el-date-picker v-model="ActiveWeek" type="week" @change="weekUpdated()" format="DD - MM - YYYY"
					placeholder="Pick a week">
				</el-date-picker>
			</div>
			<div class="block2">
				<img src="./../assets/images/arrow_forward.svg" @click="nextWeek" />
			</div>
		</div>

		<!-- loading skelton -->
		<div class="sidenav-skelton" v-if="loading">
			<el-skeleton>
				<template #template>
					<div v-for="index in 7" :key="index" class="day-info place-center">
						<div class="block1">
							<span class="day">
								<label class="checkbox">
									<el-skeleton-item variant="button" style="width: 20px; height: 20px" />
								</label>
								<el-skeleton-item variant="text" style="width: 100px; margin-left: 5px" />
							</span>
							<span class="date">
								<input type="checkbox" style="visibility: hidden" />
								<el-skeleton-item variant="text" style="width: 100px; margin-left: 5px" />
							</span>
						</div>

						<div class="block2">
							<span class="hours"><el-skeleton-item variant="button"
									style="width: 20px; height: 20px" /></span>
						</div>
					</div>
				</template>
			</el-skeleton>
		</div>
		<!-- sidenav -->
		<div v-if="!loading">
			<div v-for="(item, index) in weekDays" :key="item" style="cursor: not-allowed">
				<div @click="activeTab(index, item)" :id="{}" :class="{
					active: activeTabInde === index,
					greyout: isHoliday(item.EntryDate).length,
				}">
					<div class="day-info place-center">
						<div class="block1">
							<span class="day" :class="{ absent: item.hours == 0 }">
								<label class="checkbox">
									<input type="checkbox" :id="item.EntryDate" :checked="item.is_present"
										:name="item.EntryDate" @click.stop="markAttendance($event)" />
								</label>

								{{ moment(item.EntryDate).format("dddd") }}
							</span>
							<span class="date">
								<input type="checkbox" style="visibility: hidden" />
								({{ moment(item.EntryDate).format("Do MMMM YYYY") }} )
							</span>
						</div>

						<div class="block2">
							<span class="hours" :class="{ absent: item.total_hours == 0 }">{{ item.total_hours }} hrs</span>
						</div>
					</div>
					<div class="holiday-block">
						<span v-if="isHoliday(item.EntryDate).length" class="holiday-icon">
							<span>
								<fa :icon="['fas', 'calendar-day']" />
							</span>
							Holiday - {{ isHoliday(item.EntryDate)[0].Description }}
						</span>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import moment from "moment";
import week from "../services/week.js";
export default {
	name: "sideNavbar",
	props: ["navFlag", "activeTabIndex"],
	data() {
		return {
			att: "",
			activeTabInde: moment().weekday(),
			ActiveWeek: moment().startOf("week").format("YYYY-MM-DD"),
			weekDays: [],
			holiday_list: "",
			checkList: [],
			week_date: "",
			loading: true,
		};
	},
	computed: {
		getCounter() {
			return this.$store.getters.getHours;
		},
	},
	watch: {
		getCounter() {
			this.loading = true;
			this.getWeekDeatils(this.ActiveWeek);
		},
	},

	mounted() {
		//get current week details
		this.getWeekDeatils(this.ActiveWeek);
		this.week_date = this.ActiveWeek;
	},
	created: function () {
		this.moment = moment;
	},
	methods: {
		prevWeek() {
			this.loading = true;
			let new_week = moment(this.week_date)
				.subtract(7, "days")
				.format("YYYY-MM-DD");
			this.week_date = new_week;
			this.getWeekDeatils(new_week);
			this.ActiveWeek = new_week;
			this.checkActiveweekIndex();
		},
		nextWeek() {
			this.loading = true;
			let new_week = moment(this.week_date).add(7, "days").format("YYYY-MM-DD");
			this.week_date = new_week;
			this.getWeekDeatils(new_week);
			this.ActiveWeek = new_week;
			this.checkActiveweekIndex();
		},

		markAttendance(event) {
			let id = event.target.id;
			let check = document.getElementById(id).checked;
			let msg = check ? "Present" : "Absent";
			this.$confirm("Are you sure you want to mark " + msg, {
				confirmButtonText: "OK",
				cancelButtonText: "Cancel",
				type: "info",
			})
				.then(() => {
					let flag = this.capitalizeFlag(check);

					week
						.markAttendance(id, flag)
						.then(() => {
							if (check) {
								this.$notify({
									title: "Success",
									type: "success",
									message: "Marked as present succesfully",
									position: "bottom-right",
									duration: 1500,
								});
								return;
							}
							this.$notify({
								title: "Success",
								type: "success",
								message: "Marked as absent succesfully",
								position: "bottom-right",
								duration: 1500,
							});
						})
						.catch((err) => {
							console.log(err);
						});
				})
				.catch(() => {
					let id = event.target.id;
					let check = document.getElementById(id).checked;
					document.getElementById(id).checked = check ? false : true;
				});
		},
		checkActiveweekIndex() {
			let index = moment(this.week_date).isSame(new Date(), "week");
			if (!index) {
				this.activeTabInde = 0;

				this.$store.dispatch(
					"addNumber",
					moment(this.ActiveWeek).format("YYYY-MM-DD")
				);
			} else {
				this.activeTabInde = moment().weekday();
				this.$store.dispatch("addNumber", moment().format("YYYY-MM-DD"));
			}
		},

		capitalizeFlag(string) {
			let s = string.toString();
			return s.charAt(0).toUpperCase() + s.slice(1);
		},
		isHoliday(date) {
			let holiday = this.holiday_list.filter((day) => day.Date == date);
			return holiday;
		},
		getWeekDeatils(act_week) {
			let new_date = act_week;
			week
				.weeklySummary(new_date)
				.then((res) => {
					this.weekDays = res.data.weekly_tot_hours;
					this.holiday_list = res.data.holiday_list;
					this.loading = false;
				})
				.catch((err) => {
					//this.loading = true;
					console.log(err);
				});
		},
		activeTab(index, item) {
			this.$emit("daySelected", index);
			this.activeTabInde = index;
			this.addNumber(item.EntryDate);
		},
		addNumber(numberInput) {
			this.$store.dispatch("addNumber", numberInput);
		},
		weekUpdated() {
			this.loading = true;
			this.week_date = moment(this.ActiveWeek).format("YYYY-MM-DD");
			const new_Date = moment(this.ActiveWeek).format("YYYY-MM-DD");
			this.getWeekDeatils(new_Date);
			this.addNumber(new_Date);
			this.checkActiveweekIndex();
		},
	},
};
</script>

<style lang="scss" scoped>
// inactive sideNav
.inactive-sidenav {
	@media screen {}
}

.greyout {
	background: #ededed;
}

.hidenav {
	display: none !important;
}

.side-nav {
	display: flex;
	position: relative;
	// justify-content: center;
	flex-direction: column;

	@media (max-width: 960px) {
		// display: none;
		justify-content: flex-start;
		position: absolute;
		z-index: 1000;
	}

	.checkbox {
		display: inline-flex;
		cursor: pointer;
		position: relative;
		vertical-align: middle;
	}

	.checkbox>span {
		color: #34495e;
		padding: 0.5rem 0.25rem;
	}

	.checkbox>input {
		height: 16px;
		width: 16px;
		-webkit-appearance: none;
		-moz-appearance: none;
		-o-appearance: none;
		appearance: none;
		border: 2px solid $primary;
		border-radius: 4px;
		outline: none;
		transition-duration: 0.3s;
		cursor: pointer;
	}

	.checkbox>input:checked {
		border: 2px solid $primary;
		background-color: $primary;
	}

	.checkbox>input:checked::before {
		content: "✔";
		// display: block;
		// text-align: center;
		// color: white;
		// position: absolute;
		// // left: 0.7rem;
		// // top: 0.2rem;
		// //bottom: 0.1rem;
		// margin: auto;
		position: absolute;
		// top: 8px;
		// left: 10px;
		font-size: 15px;
		line-height: 0.8;
		color: #fff;
		transition: all 0.2s;
	}

	.checkbox>input:active {
		border: 2px solid #34495e;
	}

	.sidenav-skelton {
		padding: 12px;
	}

	.holiday-icon {
		color: orangered;
		font-size: 13px;
		padding: 10px;
		font-weight: 800;
		// position: relative;
		// bottom: 20px;
		// left: 12%;
	}

	.date {
		color: #afadad;
		font-size: 12px;
	}

	.active {
		background: #6770b9;
		border: none;

		.hours,
		.date {
			color: white;
		}

		.absent {
			color: white !important;
		}

		.checkbox>input:checked {
			border: 2px solid white;
		}

		.day {
			color: white !important;
		}

		// .day_inner {
		//   color: white;
		// }

		.checkbox>input {
			border: 2px solid white;
		}

		.holiday-icon {
			color: white;
		}

		.day-info {
			border-bottom: 1px solid $primary;
		}

		// .holiday-icon{

		// }
	}

	.holiday-block {
		flex-direction: row;
		position: relative;
		bottom: 20px;
		text-align: center;
	}

	.holiday {
		color: #afadad;
		font-size: 12px;
		text-align: center;
		padding: 2px;
	}

	.day-info,
	.day-nav {
		width: 100%;
		// height: 100px !important;
		border-bottom: 1px solid #eee;
		border-top: 1px solid #eee;
		border-collapse: collapse;
		cursor: pointer;
		flex-direction: row;
		justify-content: space-around;
		min-height: 100px;

		@media (max-width: 960px) {
			min-height: 100px;
		}

		.block1 {
			display: flex;
			justify-content: center;
			flex-direction: column;

			.absent {
				color: #afadad;
			}
		}

		.block2 {
			.hours {
				font-size: 18px;
				font-weight: bold;
			}

			.absent {
				color: #afadad;
			}
		}

		.day {
			font-size: 18px;
			font-weight: bold;
			color: #4b5c6d;
		}
	}

	.day-nav {
		height: 60px !important;
		position: sticky;
		top: 0px;
		z-index: 200;
		background: #fff;
	}
}
</style>
