<template>
  <!-- <transition
    appear
    appear-class="custom-appear-class"
    appear-to-class="custom-appear-to-class"
    appear-active-class="custom-appear-active-class"
  > -->
  <section>
    <div class="task-intro">
      <p class="heading">Update Project Status</p>
    </div>

    <hr />

    <TaskForm />
    <transition name="component-fade" mode="out-in">
      <TaskList />
      <!-- <TaskTab /> -->
    </transition>
  </section>
  <!-- </transition> -->
</template>

<script>
import TaskForm from "@/components/home/createTask.vue";
import TaskList from "@/components/home/taskList.vue";
//import TaskTab from "../../components/home/manager/tasktab.vue";

export default {
  name: "Task",
  components: {
    TaskForm,
    TaskList
  }
};
</script>

<style lang="scss" scoped>
.task-intro {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  .heading {
    font-size: 1.8rem;
    text-align: center;
    margin: auto;
    padding: 10px;
    font-family: $font;
  }

  .note {
    width: 500px;
    color: #586673;
    font-size: 14px;
  }
}
</style>
