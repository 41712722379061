import http from ".././http-common.js";

//to get weekly details on side navbar
export default {
    personalInfo() {
        return http.get("/edm/api/personal_info/").then(res => {
            return res;
        });
    },
    downloadECard() {
        return http.get("/edm/api/eid_card/").then(res => {
            return res;
        });
    }
};
