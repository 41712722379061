import http from ".././http-common.js";

export default {
  //get all projectss
  ProjectList() {
    return http.get(`/timesheet/api/projects/`).then(res => {
      return res;
    });
  },
  //get All task at begin
  AllTask(data) {
    return http.post(`/timesheet/api/tasks_list/`, data).then(res => {
      return res;
    });
  },

  //approve task
  ApproveTask(data, taskId) {
    //what id?
    return http
      .put(`/timesheet/api/approval_status_update/${taskId}/`, data)
      .then(res => {
        return res;
      });
  },

  //get team members
  //team id
  ResourceList(projectId) {
    return http.get(`/timesheet/api/team_members/${projectId}/`).then(res => {
      return res;
    });
  },

  //task by resources
  TaskByResources(data) {
    return http.post(`/timesheet/api/tasks_by_project/`, data).then(res => {
      return res;
    });
  },

  //task by projrct
  TaskByProject(projectId, date) {
    //console.log(projectI)
    //  let projectId = 222
    return http
      .get(`/timesheet/api/tasks_by_project/?proj_id=${projectId}&date=${date}`)
      .then(res => {
        return res;
      });
  },

  sendProjectDataViaEmail(data) {
    return http.post(`/timesheet/api/project_timesheet/`, data).then(res => {
      return res;
    });
  }

};
