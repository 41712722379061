import http from ".././http-common.js";

export default {
  //to get all task of the clicked date from sidenav
  getAllTask(data) {
    return http.post("/timesheet/api/weeklysummary/", data).then(res => {
      return res;
    });
  },

  //to cretae new task. post req
  async createTask(data) {
    const res = await http.post("/timesheet/api/task/", data);
    return res;
  },
  //to update task
  updateTask(data, taskId) {
    return http.put(`/timesheet/api/task/edit/${taskId}/`, data).then(res => {
      return res;
    });
  },
  //Delete task
  deleteTask(taskId) {
    return http.delete(`/timesheet/api/task/edit/${taskId}/`).then(res => {
      return res;
    });
  },

  ProjectList() {
    let id = localStorage.getItem("P1");
    var statusParam = true
    return http.get(`/timesheet/api/project/list/${id}/${statusParam}`).then(res => {
      return res;
    });
  }
};
