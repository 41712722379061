import http from ".././http-common.js";

//to get weekly details on side navbar
export default {
  userDeatils() {
    return http.get("/timesheet/api/userdetails/1/").then(res => {
      return res;
    });
  }
};
