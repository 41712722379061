<template>
	<!-- <transition
    name="custom-classes-transition"
    enter-active-class="animate__animated animate__bounce"
    leave-active-class="animate__animated animate__bounce"
  > -->
	<section class="home-page">
		<header class="header">
			<TopNavbar
				@clicked="sideNavEvent"
				:navFlag="navFlag"
				:name="username"
				:profile_pic="profilePic"
				:role="role"
			/>
		</header>
	</section>
	<section>
		<div class="container col-md-12">
			<el-card
				class="box-card"
				v-loading="loading"
				element-loading-text="Loading..."
			>
				<template #header>
					<div class="card-header" style="justify-content: center">
						<span style="text-align: center"
							><el-image
								style="width: 40%; height: 50%"
								:src="basic_info.profile_pic"
								:fit="contain"
							/><br />{{ username }}</span
						>
					</div>
					<span
						><b>Note:</b> If any changes required please contact the HR.</span
					>
				</template>
				<el-descriptions
					title="Personal Details"
					:column="4"
					:size="size"
					direction="horizonal"
					:style="blockMargin"
				>
					<el-descriptions-item label="Employee ID:"
						><b>{{ basic_info.employee_id ?? "N/A" }}</b></el-descriptions-item
					>
					<el-descriptions-item label="Personal Email:"
						><b>{{
							basic_info.personal_mail ?? "N/A"
						}}</b></el-descriptions-item
					>
					<el-descriptions-item label="Primary Phone Number:">
						<b>{{ basic_info.primary_phone ?? "N/A" }}</b>
					</el-descriptions-item>
					<el-descriptions-item label="PAN Number:"
						><b>{{ basic_info.pan_number ?? "N/A" }}</b></el-descriptions-item
					>
					<el-descriptions-item label="Aadhar Number:"
						><b>{{
							basic_info.aadhaar_number ?? "N/A"
						}}</b></el-descriptions-item
					>
					<el-descriptions-item label="DoB:"
						><b>{{ basic_info.dob ?? "N/A" }}</b></el-descriptions-item
					>
					<el-descriptions-item label="Blood Group:"
						><b>{{ basic_info.blood_group ?? "N/A" }}</b></el-descriptions-item
					>
					<el-descriptions-item label="Birth Place:"
						><b>{{ basic_info.birth_place ?? "N/A" }}</b></el-descriptions-item
					>
					<el-descriptions-item label="Gender:"
						><b>{{ basic_info.gender ?? "N/A" }}</b></el-descriptions-item
					>
					<el-descriptions-item label="Religion:"
						><b>{{ basic_info.religion ?? "N/A" }}</b></el-descriptions-item
					>
					<el-descriptions-item label="Marital Status:"
						><b>{{
							basic_info.marital_status ?? "N/A"
						}}</b></el-descriptions-item
					>
					<el-descriptions-item label="Spouse Name:"
						><b>{{ basic_info.spouse_name ?? "N/A" }}</b></el-descriptions-item
					>
					<el-descriptions-item label="Secondary Phone:"
						><b>{{
							basic_info.secondary_phone ?? "N/A"
						}}</b></el-descriptions-item
					>
					<el-descriptions-item label="Address:" :span="4"
						><b>{{ basic_info.address ?? "N/A" }}</b></el-descriptions-item
					>
					<el-descriptions-item label="Local Address:" :span="4"
						><b>{{
							basic_info.local_address ?? "N/A"
						}}</b></el-descriptions-item
					>
					<el-descriptions-item label="Relation:"
						><b
							>{{ other_info.name ?? "N/A" }} - ({{ other_info.relation }})</b
						></el-descriptions-item
					>
					<el-descriptions-item label="Contact Number:"
						><b
							>{{ other_info.contact_number ?? "N/A" }}
						</b></el-descriptions-item
					>
					<el-descriptions-item label="Address:" :span="4"
						><b>{{ other_info.address ?? "N/A" }} </b></el-descriptions-item
					>
				</el-descriptions>
				<el-divider></el-divider>
				<el-descriptions
					title="Other Details"
					:column="4"
					:size="size"
					direction="horizonal"
					:style="blockMargin"
				>
					<el-descriptions-item label="Primary Skill:"
						><b>{{
							work_details.primary_skill__TechName ?? "N/A"
						}}</b></el-descriptions-item
					>
					<el-descriptions-item label="PF Number:"
						><b>{{ work_details.pf_number ?? "N/A" }}</b></el-descriptions-item
					>
					<el-descriptions-item label="UAN Number:" :span="4"
						><b>{{ work_details.uan_number ?? "N/A" }}</b></el-descriptions-item
					>
					<el-descriptions-item label="" :span="4"
						><el-button class="button" text @click="downloadEID"
							><fa :icon="['fas', 'download']" /> Download E-ID Card</el-button
						></el-descriptions-item
					>
				</el-descriptions>
			</el-card>
		</div>
	</section>
	<!-- </transition> -->
</template>

<script>
	import TopNavbar from "@/components/TopNavbar.vue";
	import Profile from "../services/profile.js";
	import axios from "axios";

	export default {
		name: "Home",
		data() {
			return {
				navFlag: false,
				index: 0,
				name: null,
				role: null,
				size: "32px",
				basic_info: {},
				work_details: {},
				other_info: {},
				profilePic: localStorage.getItem("profile_pic"),
				username: localStorage.getItem("username"),
				loading: false,
			};
		},
		components: {
			TopNavbar,
		},

		mounted() {
			this.getBasicInfo();
		},

		methods: {
			getBasicInfo() {
				Profile.personalInfo()
					.then((res) => {
						this.basic_info = res.data.basic_info[0];
						this.work_details = res.data.work_details[0];
						this.other_info = res.data.other_info[0];
					})
					.catch((err) => {
						console.log(err);
					});
			},
			downloadEID() {
				this.loading = true;
				axios({
					url: "https://projects.pacewisdom.in/edm/api/eid_card/",
					headers: {
						"content-type": "application/json",
						Authorization: "Bearer " + localStorage.getItem("P2"),
					},
					method: "GET",
					responseType: "blob",
				}).then((res) => {
					this.loading = false;
					var fileURL = window.URL.createObjectURL(new Blob([res.data]));
					var fileLink = document.createElement("a");

					fileLink.href = fileURL;
					fileLink.setAttribute("download", "E-ID.pdf");
					document.body.appendChild(fileLink);

					fileLink.click();
				});
			},
		},
	};
</script>

<style scoped>
	.card-header {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.text {
		font-size: 14px;
	}

	.item {
		margin-bottom: 18px;
	}

	.box-card {
		width: 90%;
		margin-top: 3%;
		margin-left: 3%;
	}
	.el-descriptions {
		margin-top: 20px;
	}
</style>