<template>
  <h3 class="date-heading">{{ moment(getNumbers).format("Do MMMM YYYY") }}</h3>
  <section class="team-task-tab">
    <!-- CARDS SKELTON -->
    <div class="loading-skelton" v-if="loading">
      <el-skeleton :loading="loading" animated>
        <template #template>
          <el-carousel height="150px" style="overflow: hidden" arrow="never">
            <div class="cards">
              <div v-for="index in 8" :key="index">
                <div :class="{ active: activeTabIndex === index }" class="card card-1">
                  <fa class="project-icon" :icon="['fas', 'tasks']" />
                  <!-- <el-svg-icon> <document-copy /> </el-svg-icon> -->
                  <el-skeleton-item class="card__title"> </el-skeleton-item>
                  <el-skeleton-item class="card__title"> </el-skeleton-item>
                </div>
              </div>
            </div>
          </el-carousel>
        </template>
      </el-skeleton>
    </div>

    <!-- cards -->
    <el-carousel v-if="!loading" height="150px" style="overflow: hidden" arrow="never">
      <button @click="slideLeft" class="left">
        <i class="el-icon-caret-left"></i>
      </button>
      <button class="right" @click="sliderRight">
        <i class="el-icon-caret-right"></i>
      </button>
      <div class="cards">
        <div v-for="(item, index) in projects" :key="index">
          <div :class="{ active: activeTabIndex === index }" class="card card-1" @click="activeTab(index, item)">
            <fa class="project-icon" :icon="['fas', 'tasks']" />
            <!-- <el-svg-icon> <document-copy /> </el-svg-icon> -->
            <h2 class="card__title">
              {{ item.Proj__ProjName }}
            </h2>
          </div>
        </div>
      </div>
    </el-carousel>

    <div class="filter" :style="{ visibility: project_team_id ? 'visible' : 'hidden' }">
      <div class="filter-options">
        <!-- <el-select v-model="value" filterable placeholder="Select Project">
            <el-option
              v-for="item in projects"
              :key="item.value"
              :label="item.label"
              :value="item.value"
              @change="change_slide"
            >
            </el-option>
          </el-select> -->
        <el-date-picker style="width:60%" @change="dateRangeUpdated()" format="YYYY/MM/DD" value-format="YYYY-MM-DD" v-model="dateRange"
          type="daterange" range-separator="To" start-placeholder="Start Date" end-placeholder="End Date" />
        <el-button :loading="reportLoading" @click="sendProjectDataViaEmail" class="filter-button" type="primary">
          Export Report
        </el-button>
      </div>
      <div class="filter-options">
        <el-select  multiple v-model="value2" filterable class="select_tag"
          placeholder="Select Resources">
          <el-option v-for="items in resources" :key="items.id" :label="items.TeamMemb__EmpName" :value="items.id">
          </el-option>
        </el-select>
        <el-button @click="getTaskByResources" class="filter-button" type="primary">
          Apply
        </el-button>
      </div>
    </div>

    <el-table :data="tableData" style="width: 100%">
      <el-table-column type="expand">
        <template #default="props" class="expand-det">
          <p class="task_desc_expanded">
            <b>Task Description: </b>{{ props.row.task_description }}
          </p>
        </template>
      </el-table-column>
      <el-table-column label="Project Name" prop="entry__EntryProject__ProjName">
      </el-table-column>
      <el-table-column label="Resource Name" prop="entry__EntryEmployee__EmpName">
      </el-table-column>
      <el-table-column label="Task Name" prop="task_name"> </el-table-column>
      <!-- <el-table-column label="Date" prop="date"> </el-table-column> -->
      <el-table-column label="Effort" width="180">
        <template #default="scope">
          <span>
            <fa :icon="['fas', 'clock']" />
          </span>
          <span style="margin-left: 10px">{{ scope.row.task_hours }} Hrs</span>
        </template>
      </el-table-column>
      <!-- STATUS -->

      <el-table-column label="Status">
        <template #default="scope">
          <el-tooltip v-if="!scope.row.entry__EntryEmployee__is_lead" placement="bottom" :content="
            scope.row.task_approval_status === 'Approved'
              ? 'Approved'
              : 'Pending'
          " effect="light">
            <el-button class="accept-button" circle size="mini"><span>
                <fa :icon="[
                  'fas',
                  scope.row.task_approval_status === 'Approved'
                    ? 'check'
                    : 'hourglass-half'
                ]" />
              </span></el-button>
          </el-tooltip>
          <!-- 
          <el-tooltip
            placement="bottom"
            content="
              Approved
            "
            effect="light"
            :style="{
              display:
                scope.row.task_approval_status === 'Pending' ? 'block' : 'none'
            }"
          >
            <el-button class=" accept-button" circle size="mini"
              ><span><fa :icon="['fas', 'pause-circle']"/></span
            ></el-button>
          </el-tooltip> -->
          <!-- <el-tag
              @click="handleEdit(scope.$index, scope.row)"
              effect="dark"
              size="medium"
              type="warning"
              class="done"
            >
              Approve
            </el-tag> -->

          <!-- <el-button
            class="reject-button"
            size="mini"
            circle
            @click="handleDelete(scope.$index, scope.row)"
            ><span><fa :icon="['fas', 'times']"/></span
          ></el-button> -->
        </template>
      </el-table-column>

      <el-table-column label="Operations" width="180">
        <template #default="scope">
          <el-tag effect="dark" size="medium" class="ongoing" round v-if="
            scope.row.task_approval_status === 'ongoing' &&
            !scope.row.entry__EntryEmployee__is_lead
          ">
            {{ scope.row.task_approval_status }}
          </el-tag>
          <el-tag effect="dark" size="medium" type="warning" class="pending" v-if="
            scope.row.task_approval_status === 'Pending' &&
            !scope.row.entry__EntryEmployee__is_lead
          " @click="ApproveTask(scope.$index, scope.row)">
            Approve
          </el-tag>
          <el-tag effect="dark" size="medium" type="success" class="approved-button" v-if="
            scope.row.task_approval_status === 'Approved' &&
            !scope.row.entry__EntryEmployee__is_lead
          ">
            Approved
          </el-tag>
        </template>
      </el-table-column>
    </el-table>
  </section>
</template>

<script>
import moment from "moment";
import Manager from "../../../services/manager";
import { ElNotification } from "element-plus";

export default {
  data() {
    return {
      project_team_id: null,
      activeTabIndex: 0,
      filterFlag: false,
      value: [],
      value2: [],
      list: [],
      loading: true,
      projects: [],
      resources: [],
      tableData: [],
      dateRange: '',
      reportLoading: false,
      project_id: null
    };
  },
  created: function () {
    this.moment = moment;
  },

  computed: {
    getNumbers() {
      return this.$store.getters.getNumbers;
    }
  },

  mounted() {
    this.getProjects();

    // this.getAllTask();
    // this.getResources();
  },
  watch: {
    project_team_id: function () {
      this.resources = [];
      this.value2 = null;

      this.getResources();
    },
    getNumbers() {
      //this.resources

      this.getAllTasksByProject();
    }
  },

  methods: {
    dateRangeUpdated() {
      if (this.dateRange === null || !this.dateRange) {
        this.dateRange = [];
      }
    },
    getTaskByResources() {
      if (!this.value2) {
        return;
      }
      let data = {
        pro_id: this.project_team_id,
        employees: this.value2,
        task_date: this.getNumbers
      };
      Manager.TaskByResources(data).then(res => {
        this.tableData = res.data.tasks.filter(
          obj => !(obj && Object.keys(obj).length === 0)
        );
        // this.tableData = res.data.tasks;
      });
    },
    activeTab(index, item) {
      this.activeTabIndex = index;
      this.project_team_id = item.id;
      this.project_id = item.Proj__id;

      //call get all task by project;
      this.getAllTasksByProject();
    },
    getResources() {
      let id = this.project_team_id;
      Manager.ResourceList(id).then(res => {
        this.resources = res.data.team_memb_list;
      });
    },
    getProjects() {
      Manager.ProjectList().then(res => {
        this.projects = res.data.projects;
        this.loading = false;
        this.project_team_id = this.projects[0].id;
        this.project_id = this.projects[0].Proj__id;
        this.getAllTasksByProject();
      });
    },
    getAllTask() {
      let data = {
        task_date: this.getNumbers
      };
      Manager.AllTask(data).then(res => {
        this.tableData = res.data.tasks.filter(
          obj => !(obj && Object.keys(obj).length === 0)
        );
        // this.tableData = res.data.tasks;
      });
    },

    getAllTasksByProject() {
      Manager.TaskByProject(this.project_team_id, this.getNumbers).then(res => {
        this.tableData = res.data.tasks;
        // this.tableData = res.data.tasks;
      });
    },

    slideLeft() {
      document.querySelector(".cards").scrollLeft -= 300;
    },
    sliderRight() {
      document.querySelector(".cards").scrollLeft += 300;
    },

    ApproveTask(index, row) {
      if (row.task_approval_status === "Approved") {
        return;
      }
      let data = {
        task_approval_status: "Approved"
      };
      Manager.ApproveTask(data, row.id)
        .then(res => {
          this.$notify({
            title: "Success",
            message: res.data.message,
            type: "success",
            position: "bottom-right",
            duration: 2000
          });
          this.getAllTasksByProject();
          //call get all task again
        })
        .catch(err => console.log(err));
    },
    async sendProjectDataViaEmail() {
      if (this.dateRange && this.dateRange.length > 0) {
        this.reportLoading = true;
        let data = {
          project_id: this.project_id,
          start_date: this.dateRange[0],
          end_date: this.dateRange[1],
        };

        await Manager.sendProjectDataViaEmail(data).then(res => {
          if (res?.data?.status === true) {
            this.reportLoading = false;
            ElNotification.success({
              title: "Success",
              message: res.data.message,
              position: "top-right"
            });
          } else {
            ElNotification.error({
              title: "Error",
              message: res.data.message,
              position: "top-right"
            });
            this.reportLoading = false;
          }

        }).catch(err => {
          console.log('err', err)
          this.reportLoading = false;
        });
      } else {
        ElNotification.error({
          title: "Error",
          message: 'Select start date and end date',
          position: "top-right"
        });
      }
    },
    // handleDelete(index, row) {
    //   console.log(index, row);
    // }
  }
};
</script>

<style lang="scss" scoped>
.team-task-tab {
  .left {
    border: none;
    outline: 0;
    padding: 0;
    margin: 0;
    height: 36px;
    width: 36px;
    cursor: pointer;
    transition: 0.3s;
    border-radius: 50%;
    background-color: rgba(31, 45, 61, 0.11);
    color: #fff;
    position: absolute;
    top: 50%;
    z-index: 10;
    transform: translateY(-50%);
    text-align: center;
    font-size: 12px;
  }

  .right {
    right: 2px;
    border: none;
    outline: 0;
    padding: 0;
    margin: 0;
    height: 36px;
    width: 36px;
    cursor: pointer;
    transition: 0.3s;
    border-radius: 50%;
    background-color: rgba(31, 45, 61, 0.11);
    color: #fff;
    position: absolute;
    top: 50%;
    z-index: 10;
    transform: translateY(-50%);
    text-align: center;
    font-size: 12px;
  }

  .cards {
    // display: flex;
    // flex-wrap: wrap;
    // justify-content: space-between;
    display: grid;
    overflow: hidden;
    grid-auto-flow: column;
    grid-auto-columns: minmax(240px, 1fr);
    scroll-behavior: smooth;
  }

  .card {
    background-color: white;
    margin: 20px;
    padding: 20px;
    width: 150px;
    min-height: 70px;
    // display: grid;
    // grid-template-rows: 20px 50px 1fr 50px;
    border-radius: 10px;
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.1);
    transition: all 0.2s;
    cursor: pointer;
  }

  .active {
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.4);
    transform: scale(1.01);
    background-color: #474c7e;
    color: white;

    .card__title {
      color: white;
    }

    .project-icon {
      color: white;
    }
  }

  .card__title {
    grid-row: 3/4;
    font-weight: 900;
    color: #474c7e;
    font-size: 12px;
  }

  .project-icon {
    font-weight: 900;
    color: #474c7e;
    font-size: 12px;
  }

  /* CARD BACKGROUNDS */

  // .card-1 {
  //   //background: white;
  // }

  .filter {
    padding: 10px 0 10px 0;
    flex-direction: row;
    display: flex;

    .fade-enter-active,
    .fade-leave-active {
      transition: opacity 0.5s ease;
    }

    .fade-enter-from,
    .fade-leave-to {
      opacity: 0;
    }

    .filter-button {
      margin-left: 10px;
    }

    .filter-options {
      padding: 10px 0 10px 0;
      flex: 1;
      flex-direction: row;
      align-content: flex-end;
    }

    .select_tag {
      padding-left: 20px;
      width: 70%;
    }
  }

  .accept-button {
    color: #43a784;
    font-size: 16px;
    background: #edfcf5;
    border: none;
    cursor: auto;
  }

  .approved-button {
    color: #afadad;
    font-size: 14px;
    background: #ede8e8;
    border: none;
    cursor: not-allowed;
  }

  .reject-button {
    color: #de0654;
    font-size: 16px;
    background: #ffe9f0;
    border: none;
  }

  .expand-det {
    border: 12px solid red;
  }

  .ongoing {
    color: white;
    background-color: #2ebdc9;
    border: none;
    // font-weight: 900;
  }

  .done {
    color: white;
    background-color: lightgreen;
    border: none;
    cursor: pointer;
  }

  .pending {
    color: white;
    background: #ffdc3d;
    border: none;
    // font-weight: 900;
    cursor: pointer;
    font-size: 14px;
  }

  .task_desc_expanded {
    letter-spacing: 1px;
  }
}
</style>
