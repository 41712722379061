<template>
	<!-- <transition
    name="custom-classes-transition"
    enter-active-class="animate__animated animate__bounce"
    leave-active-class="animate__animated animate__bounce"
  > -->
	<section class="home-page">
		<header class="header">
			<TopNavbar
				@clicked="sideNavEvent"
				:profile_pic="profile_pic"
				:navFlag="navFlag"
				:name="name"
				:role="role"
			/>
		</header>
		<main class="main" v-if="role">
			<sideNavbar
				class="sidenav"
				:navFlag="navFlag"
				:activeTabIndex="index"
				@daySelected="daySelected"
			/>

			<Task class="task" v-if="role === 'user'" />
			<TaskTab class="task" v-else />
		</main>
	</section>
	<!-- </transition> -->
</template>

<script>
	import TaskTab from "@/components/home/manager/task.vue";
	import TopNavbar from "@/components/TopNavbar.vue";
	import sideNavbar from "@/components/sideNavbar.vue";
	import Task from "@/components/home/task.vue";
	import User from "@/services/user";

	export default {
		name: "Home",
		data() {
			return {
				navFlag: false,
				index: 0,
				name: null,
				role: null,
				profile_pic: null,
			};
		},
		components: {
			TopNavbar,
			sideNavbar,
			Task,
			TaskTab,
		},
		beforeMount() {
			this.getUserDetails();
		},
		methods: {
			getUserDetails() {
				User.userDeatils()
					.then((res) => {
						this.name = res.data.user_details.name;
						if (res.data.user_details.is_lead === true) {
							this.role = "manager";
						} else {
							this.role = "user";
						}
						localStorage.setItem("P1", res.data.user_details.user_id);
						this.profile_pic = res.data.user_details.profile_pic;
						localStorage.setItem(
							"profile_pic",
							res.data.user_details.profile_pic
						);
						localStorage.setItem("username", this.name);
						localStorage.setItem("role", this.role);
					})
					.catch((err) => console.log(err));
			},

			sideNavEvent(flag) {
				this.navFlag = flag;
			},

			daySelected(index) {
				this.index = index;
			},
		},
	};
</script>

<style lang="scss" scoped>
	.home-page {
		//background-color: #f2f5fb;
		.header {
			position: sticky;
			top: 0px;
			z-index: 500;
		}
		.main {
			display: flex;
			// background-color: #f2f5fb;
			.sidenav {
				width: 300px;
				background: #fff;
				box-shadow: 0 5px 10px rgba(154, 160, 185, 0.05),
					0 15px 40px rgba(166, 173, 201, 0.2);
				height: 100%;
				margin: 2rem;
				max-height: calc(100vh - 100px - 3rem);
				overflow: auto;
				@media (max-width: 960px) {
					height: 100vh;
					max-height: initial;
					margin: 0px;
				}
			}

			.task {
				width: calc(100% - 300px - 4rem);
				overflow: auto;
				background: #fff;
				// box-shadow: 0 5px 10px rgba(154, 160, 185, 0.05),
				//   0 15px 40px rgba(166, 173, 201, 0.2);
				height: calc(100vh - 100px - 3rem);
				padding: 2rem;
				//  background-color: #f2f5fb;

				@media (max-width: 960px) {
					width: 100%;
					padding: 1rem;
				}
			}
		}
	}
</style>
